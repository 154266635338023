import React, { useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"
import { motion, useAnimation } from "framer-motion"
import Seo from "../components/seo"
import styled from "styled-components"
import Grid from "../components/imageGrid/grid"
import { LocomotiveScrollProvider } from "react-locomotive-scroll"
import Image from "../components/imageGrid/image"
import { StaggerTextReveal } from "stagger-text-reveal-animation"
import { useMediaQuery } from "react-responsive"

export default function PersonalPageSingle({
  data: { personal },
  location,
  pageContext,
}) {
  const { prev, next } = pageContext
  console.log(prev)
  console.log(next)
  //styles
  const SinglePage = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    .navigation {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      width: 50vw;
      bottom: 40px;
      z-index: 1;
      @media (min-width: 768) {
        top: 50%;
        width: calc(100vw - 20%);
        bottom: 0;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      @media (min-width: 1200px) {
        top: 50%;
        width: calc(100vw - 30%);
        bottom: 0;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      a {
        color: var(--yellow);
        font-size: 14px;
        text-decoration: none;
        text-transform: uppercase;
        font-family: "PPNeue", "courier", sans-serif;
        &.disabled {
          opacity: 0.5;
        }
      }
      .left {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .right {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  `
  const ContentWrap = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
    padding: 4vw;
    width: 100%;
    transform-origin: 50% 50%;
    @media (min-width: 768px) {
      width: 50vw;
      padding: 4vw;
    }
    @media (min-width: 1100px) {
      width: 30vw;
      padding: 0;
    }
    .text {
      position: absolute;

      left: 0;
      right: 0;
      display: grid;
      grid-template-columns: repeat(1, 1fr);

      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
      color: var(--white);
      margin-top: 15px;
    }
    .title {
      display: flex;
      align-items: center;
      h2 {
        margin: 0;
      }
    }
    .body {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: "PPneueregular";
      @media (min-width: 768px) {
        justify-content: flex-end;
      }
      p {
        font-family: "PPneueregular";
        margin: 0;
        padding: 0;
      }
    }
  `
  const ImageWrapper = styled.div`
    position: relative;
  `

  //locomotive scroll
  const containerRef = useRef(null)

  //mediaqueies

  //queries
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  })

  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1150px)" })
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" })

  return (
    <SinglePage className="singlepage">
      <div className="navigation">
        <div className="left">
          <Link
            className={prev ? "" : "disabled"}
            to={prev ? "/personal/" + prev : "#"}
          >
            Previous
          </Link>
        </div>

        <div className="right">
          <Link
            className={next ? "" : "disabled"}
            to={next ? "/personal/" + next : "#"}
          >
            Next
          </Link>
        </div>
      </div>
      <ContentWrap
        as={motion.div}
        className="container"
        initial={{ opacity: 1 }}
        animate={{
          width: isTabletOrMobile
            ? isMobile
              ? "calc(100vw)"
              : "calc(100vw - 30%)"
            : isBigScreen
            ? "calc(100vw - 60%)"
            : "calc(100vw - 60%)",
        }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.25,
          ease: [0.43, 0.13, 0.23, 0.96],
        }}
      >
        <ImageWrapper>
          <Image
            as={motion.div}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ scale: 0 }}
            transition={{
              duration: 0.25,
              ease: [0.43, 0.13, 0.23, 0.96],
            }}
            image={personal.image.asset.gatsbyImageData}
          />
          <div className="text">
            <div className="title">
              <h3>
                <StaggerTextReveal
                  triggerAfter={0.5}
                  fontSize={25}
                  text={personal.name}
                />
              </h3>
            </div>
            <div className="body">
              <p>{personal.description}</p>
            </div>
          </div>
        </ImageWrapper>
      </ContentWrap>
    </SinglePage>
  )
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query ($slug: String!) {
    personal: sanityPersonal(slug: { current: { eq: $slug } }) {
      name
      description
      slug {
        current
      }
      image {
        asset {
          gatsbyImageData(
            placeholder: NONE
            layout: FULL_WIDTH
            width: 1400
            breakpoints: [750, 1080, 1366, 1920]
          )
        }
      }
    }
  }
`
